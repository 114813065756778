<template>
  <main class="bg-primary-transparent xl:px-6 lg:px-6 md:px-8 px-3 pb-16">
    <section class="w-full">
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <main>
        <h1 class="text-xl font-bold capitalize">My Payments</h1>
        <section class="w-full flex items-center">
          <div class="w-full flex xl:flex-row lg:flex-row md:flex-col flex-col">
            <dashboard-card
              class="bg-primary-color mr-6"
              @openModal="fundingWallet = true"
              @toggleBalance="toggleWalletBalance"
              :show-balance="showWalletBalance"
              :data-set="'balance'"
            >
              <img
                src="@/assets/images/subscription-icon-light.svg"
                alt="go to subscriptions"
                slot="icon"
                class="w-8"
              />
              <span slot="title">Wallet Balance</span>
              <span slot="action" class="text-primary-color">Top up</span>
              <span slot="amount" class="inline-block w-14">
                <span v-if="showWalletBalance" class="w-full"
                  >₦{{ walletBalance }}</span
                >
                <img
                  v-else
                  src="@/assets/images/encrypt.svg"
                  alt="wallet balance"
                  class="w-full"
                />
              </span>
            </dashboard-card>
            <dashboard-card
              class="bg-primary-green dark:bg-dark-mode-green"
              @toggleBalance="toggleRewardPoints"
              :show-balance="showRewardPoints"
              :data-set="'rewards'"
            >
              <img
                src="@/assets/images/reward-pack.svg"
                alt="go to subscriptions"
                slot="icon"
              />
              <span slot="title">Total Reward points</span>
              <span
                slot="action"
                class="text-primary-green dark:text-dark-mode-green"
                >Redeem</span
              >
              <span slot="amount" class="inline-block w-14">
                <span v-if="showRewardPoints" class="full">{{
                  rewardPoints
                }}</span>
                <img
                  v-if="!showRewardPoints"
                  src="@/assets/images/encrypt.svg"
                  alt="reward points"
                  class="w-full"
                />
              </span>
            </dashboard-card>
          </div>
        </section>
      </main>
      <section class="my-12">
        <main
          class="
            mt-6
            grid
            xl:grid-cols-6
            lg:grid-cols-4
            md:grid-cols-3
            grid-cols-2
            gap-4
          "
        >
          <quick-actions-card
            :card-data="{
              background: 'rgba(253, 79, 0, 0.1)',
              src: 'payment-card.svg',
              alt: 'visit legend zone',
              heading: 'Legend Services',
              headingColor: '#FD4F00',
              content: 'Make Payments for your legend services.',
            }"
            @openModal="$router.push({ name: 'LegendServices' })"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(131, 181, 96, 0.1)',
              src: 'payment-card-green.svg',
              alt: 'make direct payment',
              heading: 'Direct Payment',
              headingColor: '#83B560',
              content: 'Make Direct Payment for your in-store transactions.',
            }"
            @openModal="$router.push({ name: 'DirectPayment' })"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(152, 210, 225, 0.2)',
              src: 'bolt.svg',
              alt: 'pay utility bills',
              heading: 'Utilities',
              headingColor: '#5D9CAC',
              content: 'Easily make payments for your utility bills.',
            }"
            @openModal="$router.push({ name: 'Utilities' })"
          />
          <quick-actions-card
            :card-data="{
              background: 'rgba(0, 180, 84, 0.1)',
              src: 'transfer.svg',
              alt: 'Transfer Funds',
              heading: 'Transfer Funds',
              headingColor: '#00B454',
              content: 'Send funds from your wallet to another.',
            }"
            @openModal="transferringFunds = true"
          />
        </main>
      </section>
    </section>
    <section v-if="recentTransactions" class="mt-12 relative">
      <h1 class="font-bold text-lg mb-4">Recent Transactions</h1>
      <!-- <input
        type="search"
        class="
          block
          w-80
          p-2
          pl-10
          rounded-md
          border border-border-color
          bg-transparent
          focus:outline-none
        "
      />
      <search-icon
        size="1.3x"
        class="custom-class absolute bottom-2.5 left-2"
      ></search-icon> -->
    </section>
    <DataTable :transactions="recentTransactions" />
    <custom-modal v-if="transferringFunds">
      <TransferFunds
        slot="cart"
        @closeModal="transferringFunds = false"
        @confirmAction="confirmAction"
      />
    </custom-modal>
    <!-- Confirm confirm fund transfer -->
    <main
      v-if="confirmTransaction"
      class="
        w-screen
        h-screen
        bg-legend-black bg-opacity-30
        fixed
        top-0
        left-0
        flex
        items-center
        justify-center
        z-50
      "
    >
      <div
        class="
          w-1/3
          py-12
          px-8
          bg-primary-background
          shadow-lg
          rounded-lg
          text-legend-gray
        "
      >
        <div>
          You are about to make a transfer of
          <span class="text-lg font-semibold"
            >N{{ beneficiary.amount | moneyFormat }}</span
          >
          to
          <span class="text-lg font-semibold capitalize">{{
            beneficiary.full_name
          }}</span>
        </div>
        <div class="my-8 text-xl text-primary-color font-semibold">
          Are You Sure?
        </div>
        <twin-button
          @leftAction="confirmTransaction = false"
          @rightAction="transferFund"
          :loading="submitting"
        >
          <span slot="left-button">No, Cancel</span>
          <span slot="right-button">Yes, Continue</span>
        </twin-button>
      </div>
    </main>
  </main>
</template>

<script>
import DashboardCard from '@/components/DashboardCard.vue'
import QuickActionsCard from '@/components/QuickActionsCard.vue'
import TransferFunds from '@/components/TransferFunds.vue'
import TwinButton from '@/components/TwinButton.vue'
import DataTable from '@/components/DataTable.vue'
// import { SearchIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'Subscriptions',
  components: {
    DashboardCard,
    QuickActionsCard,
    // SearchIcon,
    TransferFunds,
    TwinButton,
    DataTable,
  },
  mounted() {
    this.stripeTable()
    this.fetchRecentTransactions()
  },
  watch: {
    mode() {
      this.stripeTable()
    },
  },
  data() {
    return {
      recentTransactions: null,
      showWalletBalance: false,
      showRewardPoints: false,
      notification: null,
      transferringFunds: false,
      confirmTransaction: false,
      submitting: false,
      beneficiary: null,
    }
  },
  computed: {
    ...mapGetters(['walletBalance', 'rewardPoints', 'mode']),
  },
  methods: {
    toggleWalletBalance(value) {
      value ? (this.showWalletBalance = true) : (this.showWalletBalance = false)
    },
    toggleRewardPoints(value) {
      value ? (this.showRewardPoints = true) : (this.showRewardPoints = false)
    },
    stripeTable() {
      const nodes = document.querySelectorAll('.data-items')
      nodes.forEach((node, index) => {
        if (this.mode !== 'dark' && index % 2 === 0) {
          node.classList.remove('bg-legend-black')
          node.classList.add('bg-gray-100')
        } else if (this.mode === 'dark' && index % 2 === 0) {
          node.classList.remove('bg-gray-100')
          node.classList.add('bg-legend-black')
        }
      })
    },
    async fetchWalletBalance() {
      try {
        const { status, data } = await this.axios.get('/account/wallet')
        if (status === 200) {
          this.$store.commit('SET_WALLET_BALANCE', data.data.balance)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    confirmAction(payload) {
      this.confirmTransaction = true
      this.beneficiary = payload
    },
    async transferFund() {
      try {
        if (this.submitting) {
          return
        }

        this.submitting = true

        const { status, data } = await this.axios.post(
          '/account/wallet/transfer',
          {
            amount: Number(this.beneficiary.amount),
            username: this.beneficiary.username,
            pin: this.beneficiary.transaction_pin,
          }
        )

        if (status === 200) {
          this.confirmTransaction = false
          this.transferringFunds = false
          this.submitting = false

          this.notification = {
            type: 'success',
            message: data.message,
          }

          await this.fetchWalletBalance()
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.confirmTransaction = false
        this.transferringFunds = false
        this.submitting = false
      }
    },
    async fetchRecentTransactions() {
      try {
        const { status, data } = await this.axios.get(
          '/account/transactions?filter=recent'
        )

        if (status === 200) {
          this.recentTransactions = data.data.transactions
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
